<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="安装位置" prop="address" style="width: 25%">
              <a-input v-model="queryParams.address"></a-input>
            </a-form-model-item>
            <a-form-model-item label="设备编号" prop="devicenun" style="width: 25%">
              <a-input v-model="queryParams.devicenun"></a-input>
            </a-form-model-item>
            <a-form-model-item label="TF卡状态" prop="tf_state" style="width: 25%">
              <a-select v-model="queryParams.tf_state">
                <a-select-option value="2">疑似TF卡缺失</a-select-option>
                <a-select-option value="3">疑似TF卡损坏</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="是否为历史数据" prop="is_history" style="width: 25%">
                <a-select v-model="queryParams.is_history">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="0">否</a-select-option>
                  <a-select-option value="1">是</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :customRow="customRow" :data-source="tableData" row-key="tf_id" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="work_time" slot-scope="value">
            <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''">
              <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD') : ''}}</span>
            </a-tooltip>
          </span>
          <span slot="tf_state" slot-scope="value">
            <span :title="value=='2'?'疑似TF卡缺失':'疑似TF卡损坏'">{{value=='2'?'疑似TF卡缺失':'疑似TF卡损坏'}}</span>
          </span>
          <span slot="is_history" slot-scope="value">
            <span :title="value=='0'?'否':'是'">{{value=='0'?'否':'是'}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.tf_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.tf_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import { findTFAbnormalListByCondition } from "A/ai";
export default {
  name: "tfAbnormal",
  mixins: [deptselect,pagination],
  components: {},
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams:{
        address:'',
        devicenun:'',
        tf_state:'',
        is_history:'0',
      },
      tableColumns:[
        {
          title: '安装位置',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '设备编号',
          dataIndex: 'devicenum',
          key: 'devicenum',
          ellipsis: true,
        },
        {
          title: '设备名称',
          dataIndex: 'devicename',
          key: 'devicename',
          ellipsis: true,
        },
        {
          title: 'TF卡状态',
          dataIndex: 'tf_state',
          key: 'tf_state',
          ellipsis: true,
          scopedSlots: { customRender: 'tf_state' },
        },
        {
          title: '异常时间',
          dataIndex: 'abnormaltime',
          key: 'abnormaltime',
          ellipsis: true,
          scopedSlots: { customRender: 'abnormaltime' },
        },
        {
          title: '历史数据',
          dataIndex: 'is_history',
          key: 'is_history',
          ellipsis: true,
          scopedSlots: { customRender: 'is_history' },
        },
        // {
        //   title: '操作',
        //   dataIndex: 'operation',
        //   key: 'operation',
        //   ellipsis: true,
        //   align:'center',
        //   scopedSlots: { customRender: 'operation' },
        //   width: 70
        // },
      ],
      userdepidCascaderSelected: [],
      queryInstallDate:null,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "TF卡异常"
    },
    breadcrumb() {
      const pages = [{name:"网络管理",path:""}]
      pages.push({name:"自检管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
    queryInstallDate(val) {
      let {start, end} = getTimeRange(val);
      this.queryParams.starttime = start;
      this.queryParams.endtime = end;
    },
  },
  created() {
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      findTFAbnormalListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.queryInstallDate = [];
      this.$refs.queryForm.resetFields();
      this.queryParams.is_history='0'
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let tf_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'tf_id', tf_id);
      this.showModal(type,record)
    },
    showModal(type,record) {
      this.modalDetailData = record
      this.modalShowType = type
      this.modalVisible = true
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType='detail'
            this.modalVisible=true
          },
        },
      }
    },
  },
}
</script>
<style scoped>

</style>